@font-face {
    font-display: swap;
    font-family: 'BLA';
    font-weight: normal;
    font-style: normal;
    src: url('BrutalistaAlt-Regular.woff2') format('woff2'), url('BrutalistaAlt-Regular.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'BLA';
    font-weight: normal;
    font-style: italic;
    src: url('BrutalistaAlt-RegularItalic.woff2') format('woff2'), url('BrutalistaAlt-RegularItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'BLA';
    font-weight: 500;
    font-style: normal;
    src: url('BrutalistaAlt-Medium.woff2') format('woff2'), url('BrutalistaAlt-Medium.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'BL';
    font-weight: normal;
    font-style: normal;
    src: url('Brutalista-Regular.woff2') format('woff2'), url('Brutalista-Regular.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'BL';
    font-weight: 600;
    font-style: normal;
    src: url('Brutalista-SemiBold.woff2') format('woff2'), url('Brutalista-SemiBold.woff') format('woff');
}

